const duration = 15;
const fontSize = 20
const lineHeight = 40

const colors = [
      "#FFD96F",  // 黄
      "#FF5956",  // 红
      "#24F5EF",  // 蓝
]

export function barrage(el) {

      let textlist = [];
      setInterval(() => {
            if (textlist.length === 0) {
                  return;
            }
            let line = Math.floor(Math.random() * 3);
            for (let i = el.children.length - 1; i >= 0; i--) {
                  let lineBox = el.children[i];
                  if (lineBox.line === line) {
                        if ((lineBox.offsetLeft + lineBox.offsetWidth) > el.offsetWidth ) {
                              return
                        }
                  }
            }

            let text = textlist.shift();
            let elWidth = el.offsetWidth;

            const div = document.createElement('div');
            div.line = line;
            div.style.position = "absolute";

            div.style.top = line * lineHeight + 'px';
            div.style.color = colors[Math.floor(colors.length * Math.random())]
            div.style.left = elWidth + "px";
            div.style.fontSize = fontSize + 'px';
            div.style.fontWeight = 'bold';
            div.style.textShadow = 'rgba(0, 0, 0, 0.24) 0px 1px 2px';
            div.style.wordBreak = 'break-all';
            div.style.whiteSpace = 'nowrap';
            div.style.transition = `all ${duration}s linear 0s`;
            div.textContent = text;
            el.appendChild(div);
            setTimeout(() => {
                  div.style.left = -div.offsetWidth + 'px'
                  setTimeout(() => {
                        div.parentNode && div.parentNode.removeChild(div);
                  }, (duration) * 1000);
            }, 100);
      }, 100);
      return {
            emit(text) {
                  textlist.push(text);
            }
      }
}